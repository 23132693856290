import React from "react";
import { Flex, Spacer, useMatchMedia } from "@badi/badi-components";
import { getCDNUrl } from "base/shared/Utils";
import { translate } from "base/shared/Localization";
import BadiLogo from "components/Icons/BadiLogoDisplay";
import Footer from "components/Layout/Footer";
import {
  FullWidthFlex,
  ModalWrapper,
  BadiHomesButton,
  BadiHomesMenu,
  JustifiedSpacer,
  NewTitleWrapper,
  LongText,
  ImageWrapper,
  ImageWrapperBg,
  BadiHomesSubtitle,
} from "./styled-components";
import { QUERIES, BADI_PLUS_BUTTON_UTM, BADI_PLUS_NAV_UTM } from "./constants";

const SelectorModal = ({ onClose: handleClose, onLink: handleLink }) => {
  const [isDesktop, isMedium] = useMatchMedia(QUERIES);
  let responsiveRight = isMedium ? 5 : 0;
  if (isDesktop) responsiveRight = 10;
  return [
    <ModalWrapper alignItems="center" direction="column">
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Spectral&display=swap');
      </style>
      <JustifiedSpacer bottom={0.5}>
        <BadiLogo color={"#283618"} width={"63px"} />
        {isMedium && (
          <Flex alignItems="space-around" direction="row" gutter={6}>
            <BadiHomesMenu onClick={() => handleLink(BADI_PLUS_NAV_UTM)}>
              {translate("home.selector.badihomes.menu")}
            </BadiHomesMenu>
            <BadiHomesMenu onClick={handleClose}>
              {translate("home.selector.badi.menu")}
            </BadiHomesMenu>
          </Flex>
        )}
      </JustifiedSpacer>

      <FullWidthFlex
        alignItems="center"
        basis={0}
        direction={isMedium ? "row" : "column"}
        grow={1}
        gutter={isMedium ? 5 : 4}
        shrink={1}
        wide={true}
      >
        <Spacer
          basis={0}
          grow={1}
          right={responsiveRight}
          top={isDesktop ? 0 : 3}
        >
          <Flex alignItems="flex-start" direction="column" gutter={1}>
            <NewTitleWrapper>
              <BadiHomesSubtitle>
                {translate("home.selector.badihomes.title.new")}
              </BadiHomesSubtitle>
            </NewTitleWrapper>
            <Spacer bottom={2}>
              <LongText>{translate("home.selector.badihomes.desc")}</LongText>
            </Spacer>
            <BadiHomesButton onClick={() => handleLink(BADI_PLUS_BUTTON_UTM)}>
              {translate("home.selector.badihomes.cta")}
            </BadiHomesButton>
          </Flex>
        </Spacer>
        <ImageWrapper
          basis={0}
          grow={1.5}
          picture={`${getCDNUrl()}/assets/images/home/badihomes-into-banner.jpeg`}
          wide={true}
        />
      </FullWidthFlex>
      <Spacer top={5} />
      <FullWidthFlex
        alignItems="strech"
        basis={0}
        bgColor="rgba(47, 192, 156, 0.13)"
        direction={isMedium ? "row" : "column-reverse"}
        grow={1}
        gutter={isMedium ? 5 : 0}
        shrink={1}
        wide={true}
      >
        <ImageWrapperBg
          basis={0}
          grow={2}
          picture={`${getCDNUrl()}/assets/images/home/badihomes-market-bg.png`}
        />
        <Spacer
          basis={0}
          bottom={isMedium ? 4 : 2}
          grow={1}
          right={responsiveRight}
          top={isMedium ? 4 : 2}
        >
          <Flex alignItems="flex-start" direction="column" gutter={1}>
            <Spacer left={isMedium ? 0 : 2} right={isMedium ? 0 : 2}>
              <BadiHomesSubtitle>
                {translate("home.selector.badi.title.new")}
              </BadiHomesSubtitle>
            </Spacer>
            <Spacer
              bottom={isMedium ? 2 : 2}
              left={isMedium ? 0 : 2}
              right={isMedium ? 0 : 2}
            >
              <LongText>{translate("home.selector.badi.desc")}</LongText>
            </Spacer>
            <Spacer left={isMedium ? 0 : 2} right={isMedium ? 0 : 2}>
              <BadiHomesButton onClick={handleClose}>
                {translate("home.selector.badi.cta")}
              </BadiHomesButton>
              <Spacer bottom={isMedium ? 0 : 2} />
            </Spacer>
          </Flex>
        </Spacer>
      </FullWidthFlex>
    </ModalWrapper>,
    <Footer />,
  ];
};

export default SelectorModal;
