import React, { useEffect } from "react";
import Footer from "components/Layout/Footer";
import LayoutWrapper from "components/Layout/Wrapper";
import { Header } from "./components/";
import EmptyState from "./requests-swiper/empty-state";
import { getPathToRequests, getPathToChats, SUBPATHS } from "./routes";
import styled from "styled-components";
import { mapToModel } from "./utils";
import { parseConnections } from "datamodel/Inbox/utils";
import {
  fetchDataForChats,
  fetchDataForRequests,
  findRequestWithId,
} from "./hooks";
import { BADI_COLORS, COLORS } from "app/badi-components";
import { FilterButton, mediaquery } from "@badi/badi-components";
import { Link } from "react-router";
import { InboxListerModel as model } from "./models";
import { translate } from "base/shared/Localization";
import { getPublicAsset } from "base/shared/Utils";
import { acceptEnquiry } from "app/datamodel/Conversation/api";
import { leaveInboxConversation } from "datamodel/Conversation/api";
import {
  openNeedMoreCandidatesModal,
  openRequestAcceptedModal,
} from "./modals";
import matchPaths from "./utils/matchPaths";
import LocalStorage, { STORAGE_KEYS } from "lib/local-storage/LocalStorage";
import { getInboxPath } from "../inbox/routes";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px 0;
  margin-top: 20px;
`;

Header.Inbox = styled.h1`
  text-align: center;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0 50px;
  width: 40%;
  flex: 0 0 100%;
  order: 3;

  ${mediaquery.md`
      order:2;
      flex:1;
  `}
`;
const SortedFilterButton = styled.div`
  order: 2;
  ${mediaquery.md`
      order:3;
    `}
`;

Nav.Link = styled(Link)`
  ${({ emphasised }) => `color: ${emphasised ? "#000000" : "#CACACA"};`}
  padding: 1px;
  padding-top: 15px;
  padding-bottom: 10px;
  position: relative;
  padding-right: 35px;
  :hover {
    text-decoration: none;
    :after {
      opacity: 1;
    }
  }
  ${mediaquery.md`
      padding-bottom: 20px;
      padding-top: 0;
  `}
  :after {
    content: "";
    height: 8px;
    ${({ emphasised }) =>
      `background-color: ${emphasised ? BADI_COLORS.BRAND.PRIMARY.DEFAULT : "#CACACA"};`}
    opacity:0;
    display: block;
    position: absolute;
    bottom: -4px;
    left: 20%;
    width: 60%;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
`;

Nav.Icon = styled.img`
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-right: 10px;
  ${({ emphasised }) => `opacity: ${emphasised ? "1" : ".5"};`}
`;

Nav.Count = styled.span`
  width: 30px;
  height: 30px;
  display: block;
  ${({ emphasised }) =>
    `background-color: ${emphasised ? BADI_COLORS.BRAND.PRIMARY.DEFAULT : "#CACACA"};`}
  border-radius: 999999px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  right: 0;
  top: -5px;
  font-size: 80%;
`;

const HeaderAndNavContainer = styled.div`
  border-bottom: 1px solid ${COLORS.NEUTRAL_10};
  width: 100%;
`;
const HeaderAndNavContainerWrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify: space-between;
  align-items: baseline;
  padding: 0 20px;
  padding-bottom: 0;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const getPathToRequest = (id) => {
  if (!id) {
    return null;
  }
  return getPathToRequests(id);
};

const InboxLister = ({
  params: { id },
  location,
  router,
  token,
  reload,
  children,
  isUserInListerMode,
  cleanInboxRoom,
  roomTitle = null,
  room = null,
}) => {
  if (!isUserInListerMode) {
    router.push(getInboxPath());
    return null;
  }
  const { room: roomId, category, page } = location.query;
  const requestResponse = fetchDataForRequests(token, id, room);
  const { currentRequest, idOfPreviousRequest, idOfNextRequest, requests } =
    requestResponse;

  const matches = matchPaths(
    [SUBPATHS.REQUESTS, SUBPATHS.CHATS, SUBPATHS.CONVERSATIONS],
    location.pathname,
    { default: SUBPATHS.REQUESTS },
  );
  const chats = fetchDataForChats(
    token,
    {
      roomId,
      category,
    },
    { page },
    [matches[SUBPATHS.CONVERSATIONS]], // in order to refresh when back-button'ing out of conversations screen.
  );
  const requestModel = currentRequest
    ? mapToModel(model, currentRequest)
    : null;
  const prevRequest = idOfPreviousRequest
    ? findRequestWithId(requests, idOfPreviousRequest)
    : null;
  const prevRequestModel = prevRequest?.currentRequest
    ? mapToModel(model, prevRequest.currentRequest)
    : null;
  const nextRequest = idOfNextRequest
    ? findRequestWithId(requests, idOfNextRequest)
    : null;
  const nextRequestModel = nextRequest?.currentRequest
    ? mapToModel(model, nextRequest.currentRequest)
    : null;

  const chatsModel = chats?.data ? parseConnections(chats.data) : [];

  const requestsCount = requestResponse.pagination.total;
  const chatsCount = chats.pagination.total;

  const onClickAcceptConnection = () => {
    acceptEnquiry({ token, connectionId: currentRequest.id }).then(() => {
      const hasUserSeenRequestedAcceptedModalAlready = LocalStorage.getItem(
        STORAGE_KEYS.HAS_USER_SEEN_REQUEST_ACCEPTED_MODAL,
      );
      if (!hasUserSeenRequestedAcceptedModalAlready) {
        openRequestAcceptedModal();
        LocalStorage.setItem(
          STORAGE_KEYS.HAS_USER_SEEN_REQUEST_ACCEPTED_MODAL,
          true,
        );
      }
      reload();
    });
  };

  const onClickRejectConnection = () => {
    leaveInboxConversation(currentRequest.id, token).then(() => {
      reload();
    });
  };

  const handleOpenRoomFilter = () => {
    router.push({ pathname: "/rooms" });
  };

  const handleCleanRoomFilter = () => {
    cleanInboxRoom();
    router.push({ pathname: "/inbox/requests" });
  };

  useEffect(() => {
    if (
      matches[SUBPATHS.REQUESTS] &&
      requestResponse.isLoaded &&
      requestsCount === 0
    ) {
      openNeedMoreCandidatesModal({ token });
    }
  }, [matches[SUBPATHS.REQUESTS] && requestsCount, requestResponse.isLoaded]);

  const requestsText = translate("inbox-lister.nav-bar.requests.title");
  const chatsText = translate("inbox-lister.nav-bar.chats.title");

  const childProps = {
    connectionId: id,
    connections: chatsModel,
    location,
    router,
    chatsPagination: chats.pagination,
    requestsPagination: requestResponse.pagination,
    currentRequest: requestModel,
    prevRequest: prevRequestModel,
    nextRequest: nextRequestModel,
    pathToNextRequest: getPathToRequest(idOfNextRequest),
    pathToPreviousRequest: getPathToRequest(idOfPreviousRequest),
    onClickAcceptConnection,
    onClickRejectConnection,
    isLoaded: requestResponse.isLoaded,
    requests,
  };

  return (
    <LayoutWrapper footer={<Footer campaign="inbox_footer" />}>
      <Container>
        <HeaderAndNavContainer>
          <HeaderAndNavContainerWrapper>
            <Header.Inbox>Inbox</Header.Inbox>
            <Nav>
              <Nav.Link
                emphasised={matches[SUBPATHS.REQUESTS]}
                to={getPathToRequests()}
                decoration="none"
              >
                <Nav.Icon
                  emphasised={matches[SUBPATHS.REQUESTS]}
                  src={getPublicAsset("icons/user_add.svg")}
                />
                {requestsText}
                {!!requestsCount && (
                  <Nav.Count emphasised={matches[SUBPATHS.REQUESTS]}>
                    {requestsCount}
                  </Nav.Count>
                )}
              </Nav.Link>
              <Nav.Link
                emphasised={
                  matches[SUBPATHS.CHATS] || matches[SUBPATHS.CONVERSATIONS]
                }
                to={getPathToChats()}
              >
                <Nav.Icon
                  emphasised={
                    matches[SUBPATHS.CHATS] || matches[SUBPATHS.CONVERSATIONS]
                  }
                  src={getPublicAsset("icons/chat.svg")}
                />
                {chatsText}
                {!!chatsCount && (
                  <Nav.Count
                    emphasised={
                      matches[SUBPATHS.CHATS] || matches[SUBPATHS.CONVERSATIONS]
                    }
                  >
                    {chatsCount}
                  </Nav.Count>
                )}
              </Nav.Link>
            </Nav>
            <SortedFilterButton>
              <FilterButton
                icon={getPublicAsset("icons/filter.svg")}
                id="filter_by_room"
                onCancelFilter={handleCleanRoomFilter}
                onFilterClick={handleOpenRoomFilter}
                title={translate("inbox.dropdown_all")}
                value={roomTitle}
              />
            </SortedFilterButton>
          </HeaderAndNavContainerWrapper>
        </HeaderAndNavContainer>

        {React.Children.map(children, (child) =>
          React.cloneElement(child, childProps),
        )}
      </Container>
    </LayoutWrapper>
  );
};

export default InboxLister;
