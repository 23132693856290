import { BREAKPOINTS_PX } from "@badi/badi-components";
import envVars from "base/shared/Env";

export const QUERIES = [
  `(min-width: ${BREAKPOINTS_PX.xxl})`,
  `(min-width: ${BREAKPOINTS_PX.lg})`,
];
export const BADI_HOME_URL = `https://plus.badi.com`;
export const BADI_PLUS_NAV_UTM = `?utm_source=distributionscreen&utm_medium=web&utm_campaign=navbar`;
export const BADI_PLUS_BUTTON_UTM = `?utm_source=distributionscreen&utm_medium=web&utm_campaign=button`;
export const BADI_INFO_URL = `${envVars().BADI_DOMAIN}/guests/guest-experience`;
