import React from "react";

import { modal, MODAL_ID, MODAL_TYPE } from "containers/ReduxModal/emitter";
import SelectorModal from "./selector-modal";
import { BADI_HOME_URL } from "./constants";

export const openSelectorModal = (callback = () => {}) => {
  const closeSelectorModal = () => {
    modal.remove(MODAL_ID.HOME_SELECTOR);
    callback();
  };

  const linkSelectorModal = (UTM = "") => {
    window.location.href = BADI_HOME_URL + UTM;
  };

  modal.add(
    <SelectorModal onClose={closeSelectorModal} onLink={linkSelectorModal} />,
    {
      closable: false,
      startOpen: true,
      closeOnOutsideClick: false,
      id: MODAL_ID.HOME_SELECTOR,
      type: MODAL_TYPE.FULLSCREEN,
    },
  );
};
