import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router";
import styled from "styled-components";
import { getPublicAsset } from "base/shared/Utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { gsap } from "gsap";

import EmptyState from "./empty-state";
import Loading from "./loading";
import RequestCard from "./request-card";

const NavigationIcon = styled.img`
  height: 74px;
  width: 74px;
  margin-left: -12px;
  margin-top: -12px;
  ${({ disabled }) => (disabled ? "opacity: 0.5" : "")};
  ${({ disabled }) => (disabled ? "" : "cursor: pointer")};
`;

const GoToRequestButtonContainer = styled.div`
  margin: 0 30px;
  margin-top: 225px;
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 9999px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.75);
  pointer-events: all;
`;
const NavigationArrows = styled.div`
  position: absolute;
  z-index: 30;
  width: 130%;
  left: -15%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
`;
GoToRequestButtonContainer.Left = styled(GoToRequestButtonContainer)`
  left: 0;
`;
GoToRequestButtonContainer.Right = styled(GoToRequestButtonContainer)`
  right: 0;
`;
const GoToRequestButton = ({ to, onClick, ...props }) => {
  if (!to) {
    return (
      <GoToRequestButtonContainer>
        <NavigationIcon disabled={true} {...props} />
      </GoToRequestButtonContainer>
    );
  }
  return (
    <GoToRequestButtonContainer>
      <div onClick={onClick}>
        <NavigationIcon {...props} />
      </div>
    </GoToRequestButtonContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: horizontal;
  max-width: 800px;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
`;

export const SwiperStyle = styled(Swiper)`
  width: 100%;
  overflow: visible;

  @keyframes fadeIn {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .swiper-slide {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 250ms;
    transform: scale(1);
    opacity: 1;
    &.immutable {
      transition-property: none;
    }
    img:not(.ProgressiveImg--TinyImg) {
      opacity: 1 !important;
    }
    .ProgressiveImg--Placeholder {
      position: relative;
    }
    .ProgressiveImg--Placeholder:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      heigh: 100%;
      background-color: #f0f0f0;
      opacity: 0;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 250ms;
    }
  }
  .swiper-slide.swiper-slide-active {
    .ProgressiveImg--Placeholder:after {
      opacity: 1;
      animation: fadeIn 250ms cubic-bezier(0.4, 0, 0.2, 1) 0.6s forwards;
    }
  }
  .swiper-slide:not(.swiper-slide-active) {
    transform: scale(0.9);
    opacity: 0.6;
    img {
      opacity: 0 !important;
    }
    .ProgressiveImg--Placeholder:after {
      opacity: 1;
    }
  }
`;

const RequestsSwiper = ({
  currentRequest,
  prevRequest,
  nextRequest,
  pathToPreviousRequest,
  pathToNextRequest,
  isLoaded,
  requests,
  router,
  onClickAcceptConnection,
  onClickRejectConnection,
  ...props
}) => {
  const swiperRef = useRef(null);
  const isLocked = useRef(false);
  const lockDuration = 900;
  const [immutable, setImmutable] = useState(false);
  const reqId = currentRequest?.id;
  const indexRequest = requests.findIndex((item) => item.id === reqId);
  const prevBlankCard = indexRequest > 1;
  const nextBlankCard = indexRequest < requests.length - 2;
  const initialSlide = !!prevRequest ? (prevBlankCard ? 2 : 1) : 0;

  useEffect(() => {
    if (swiperRef.current && reqId) {
      swiperRef.current.slideTo(initialSlide, 0);
    }
  }, [reqId]);

  useEffect(() => {
    if (immutable) {
      setImmutable(false);
    }
  }, [immutable]);

  const handleBeforeSlide = (swiper) => {
    const nextIndex = swiper.activeIndex;
    const currentIndex =
      swiper.swipeDirection === "prev" ? nextIndex + 1 : nextIndex - 1;
    if (nextIndex >= 0 && nextIndex <= swiper.slides.length) {
      const nextSlide = swiper.slides[nextIndex];
      const isBlank =
        nextSlide && nextSlide.getAttribute("data-blank") === "true";
      if (isBlank) {
        swiper.slideTo(currentIndex, 0);
      }
    }
  };

  const handleTransitionEnd = (swiper) => {
    const activeSlide = swiper.slides[swiper.activeIndex];
    const dataPath = activeSlide.getAttribute("datapath");
    const currentPath = router?.getCurrentLocation().pathname;
    if (dataPath && dataPath !== currentPath) {
      console.log("nextPath:", dataPath);
      const [pathname, queryString] = dataPath.split("?");
      const query = Object.fromEntries(
        new URLSearchParams(queryString).entries(),
      );
      router.push({ pathname, query });
      setImmutable(true);
    }
  };

  if (!isLoaded) return <Loading />;
  if (!currentRequest) {
    if (isLoaded) return <EmptyState />;
    return null;
  }

  const slidePrev = () => {
    if (!isLocked.current) {
      isLocked.current = true;
      swiperRef.current.slidePrev();

      setTimeout(() => {
        isLocked.current = false;
      }, lockDuration);
    }
  };

  const slideNext = () => {
    if (!isLocked.current) {
      isLocked.current = true;
      swiperRef.current.slideNext();

      setTimeout(() => {
        isLocked.current = false;
      }, lockDuration);
    }
  };

  return (
    <Container>
      <NavigationArrows>
        <GoToRequestButton
          onClick={slidePrev}
          to={pathToPreviousRequest}
          alt="go to previous request"
          src={getPublicAsset("icons/circle-chevron-left.svg")}
          position="left"
        />
        <GoToRequestButton
          onClick={slideNext}
          to={pathToNextRequest}
          alt="go to next request"
          src={getPublicAsset("icons/circle-chevron-right.svg")}
          position="right"
        />
      </NavigationArrows>
      <SwiperStyle
        onTransitionEnd={handleTransitionEnd}
        onSlideNextTransitionStart={handleBeforeSlide}
        onSlidePrevTransitionStart={handleBeforeSlide}
        initialSlide={initialSlide}
        slidesPerView={1}
        spaceBetween={0}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {!!prevBlankCard && (
          <SwiperSlide data-blank={true} key={`prev-load`}>
            <RequestCard loadCard={true} currentCard={false} {...{ props }} />
          </SwiperSlide>
        )}
        {!!prevRequest && (
          <SwiperSlide
            className={immutable ? "immutable" : ""}
            key={`prev`}
            datapath={pathToPreviousRequest}
          >
            <RequestCard
              currentRequest={prevRequest}
              currentCard={false}
              immutable={immutable}
              {...{ props }}
            />
          </SwiperSlide>
        )}
        <SwiperSlide className={immutable ? "immutable" : ""} key={`cur`}>
          <RequestCard
            currentRequest={currentRequest}
            currentCard={true}
            immutable={immutable}
            onClickAcceptConnection={onClickAcceptConnection}
            onClickRejectConnection={onClickRejectConnection}
            {...{ props }}
          />
        </SwiperSlide>
        {!!nextRequest && (
          <SwiperSlide
            className={immutable ? "immutable" : ""}
            key={`next`}
            datapath={pathToNextRequest}
          >
            <RequestCard
              currentRequest={nextRequest}
              currentCard={false}
              immutable={immutable}
              {...{ props }}
            />
          </SwiperSlide>
        )}
        {!!nextBlankCard && (
          <SwiperSlide data-blank={true} key={`next-load`}>
            <RequestCard loadCard={true} currentCard={false} {...{ props }} />
          </SwiperSlide>
        )}
      </SwiperStyle>
    </Container>
  );
};

export default RequestsSwiper;
