import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, BADI_COLORS, BUTTON_TYPES } from "app/badi-components";
import { Heading, Spacer, Flex, Icon } from "@badi/badi-components";
import { REVIEW_MODAL_TYPE } from "components/reviews-summary-modal/constants";
import Tag from "../components/Tag";
import Image from "components/Image/Image";
import ProgressiveImg from "app/components/ProgressiveImg";
import GenderAndOccupation from "app/components/User/Sections/sections/gender-and-occupation";
import { openReviewsSummaryModal } from "components/reviews-summary-modal";
import { getReviewsSummaryHeaderText } from "components/reviews-summary-modal/utils";
import SocialProfile from "components/User/Sections/sections/social-profile";
import { getPublicAsset } from "base/shared/Utils";
import { translate } from "base/shared/Localization";
import { Text, LinkButton } from "app/badi-components";
import { mediaquery } from "@badi/badi-components";
import { getGoldUpgradePath } from "Sections/plans/routes";

import { getPathToRequests } from "../routes";
import { openYourCandidatesModal } from "../modals";
import LocalStorage, { STORAGE_KEYS } from "app/lib/local-storage/LocalStorage";

const FlexHorizontal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 30px;

  ${mediaquery.md` 
    flex-direction: row;
  `}
`;

const CardScroll = styled.div`
  height: calc(100vh - 229px);
  overflow-y: scroll;
  &.current {
    height: calc(100vh - 340px);
  }
`;

const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-bottom: 30px;
  > div {
    gap: 10px 0;
  }
`;

const ColumnRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  > div {
    gap: 10px 0;
  }
  > div:first-child {
    gap: 10px 0;
    margin-bottom: 20px;
    font-size: 90%;
  }
`;

const TagCategoryHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
`;

const UserDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

UserDetailsSection.Membership = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 0 30px;
`;

UserDetailsSection.Column = styled.div`
  border-left: 2px solid ${BADI_COLORS.ADDITIONAL.ADDITIONAL_11};
  padding-left: 10px;
  font-size: 14px;
`;

const RoomInformation = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100%);
  margin-top: 0;
  background-color: #f0f0f0;
  margin-left: -25px;
  margin-right: -25px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  justify-content: space-between;
`;

RoomInformation.Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0 10px;
  & + & {
    border-top: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  }
  padding: 10px 0;
`;
RoomInformation.SectionRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 20%;
  & + & {
    border-top: 1px solid ${BADI_COLORS.NEUTRAL.NEUTRAL_10};
  }
  padding: 10px 0;
`;

const LinkWrapper = styled.a`
  cursor: pointer;
  text-decoration: underline;

  :hover {
    text-decoration: underline;
  }
`;

const RoomPicture = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
`;

const PersonalityAndInterestsSectionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
`;

const PersonalityAndInterestsSection = ({ tags }) => (
  <PersonalityAndInterestsSectionContainer>
    <Heading level={4}>
      {translate("inbox-lister.personality-and-interests.heading")}
    </Heading>
    {Object.values(tags).map(({ key, title, iconSrc, iconAlt, entries }) => (
      <div key={key}>
        <TagCategoryHeading>
          <Image src={iconSrc} alt={iconAlt} />
          <b>{title}</b>
        </TagCategoryHeading>
        {entries.map(({ id, label }) => (
          <Tag key={id}>{label}</Tag>
        ))}
      </div>
    ))}
  </PersonalityAndInterestsSectionContainer>
);

const CtaSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0;
  padding: 0;
  padding-top: 10px;
  box-shadow: 0px 10px 10px 5px rgba(50, 50, 50, 0.75);
  clip-path: inset(-10px 0px 1px 1px);
  align-items: center;
`;

CtaSection.TextSubsection = styled.div`
  flex-grow: 4;
  text-align: center;
`;

CtaSection.LinkButton = styled(LinkButton)`
  white-space: nowrap;
`;

CtaSection.AcceptAndRejectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding-bottom: 2px;
`;

const TopSectionContainer = styled.div`
  border-radius: 30px;
  -webkit-box-shadow: 0px 0px 87px -30px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 87px -30px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 87px -30px rgba(0, 0, 0, 0.75);
  padding: 25px;
  padding-bottom: 25px;
  overflow: visible;
`;

const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
`;

const BioContainer = styled.div`
  margin-top: 10px;
`;

const UserDetailExtra = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const LanguageSpan = styled.span`
  margin-right: 5px;
`;

const UserDetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px;
  > h3 {
    margin-bottom: 12px;
  }
  p {
    font-size: 0 !important;
  }
  > div {
    margin-bottom: 0;
    margin-top: -6px;
    transform: scale(0.9);
    transform-origin: right center;
  }
`;

const BadiGoldSection = styled.div``;

const RequestCard = ({
  currentRequest,
  onClickAcceptConnection,
  onClickRejectConnection,
  currentCard,
  loadCard,
}) => {
  if (loadCard) {
    return (
      <FlexVertical>
        <TopSectionContainer>
          <CardScroll className={"disabled"}>
            <FlexHorizontal>
              <Column></Column>
              <ColumnRight></ColumnRight>
            </FlexHorizontal>
          </CardScroll>
        </TopSectionContainer>
      </FlexVertical>
    );
  }

  const { user, room, hasBadiGold, payToMatchRequired, sentAt } =
    currentRequest;
  const { reviewsTotal, socialProfiles, id: profileUserId } = user;
  const isCompany = !!user.company;

  useEffect(() => {
    const hasUserSeenFirstTimeListerRequestsInformationAlready =
      LocalStorage.getItem(
        STORAGE_KEYS.HAS_USER_SEEN_FIRST_TIME_LISTER_REQUESTS_INFORMATION,
      );
    if (!hasUserSeenFirstTimeListerRequestsInformationAlready) {
      openYourCandidatesModal();
      LocalStorage.setItem(
        STORAGE_KEYS.HAS_USER_SEEN_FIRST_TIME_LISTER_REQUESTS_INFORMATION,
        true,
      );
    }
  }, []);

  const handleReviewsClick = () => {
    if (reviewsTotal > 0) {
      openReviewsSummaryModal(profileUserId, REVIEW_MODAL_TYPE.USER);
    }
  };

  return (
    <FlexVertical>
      <TopSectionContainer>
        <CardScroll className={currentCard ? "current" : "disabled"}>
          <FlexHorizontal>
            <Column>
              <ProgressiveImg
                src={user.picture}
                tinySrc={user.picture_small}
                alt="user image"
                style={{ width: "100%", alignSelf: "center" }}
              />
              <UserDetailsSection>
                <UserDetailHeader>
                  <Heading level={3}>{user.name_and_age}</Heading>
                  {!isCompany && (
                    <GenderAndOccupation
                      gender={user.gender}
                      occupation={user.workStudiesStatus}
                    />
                  )}
                </UserDetailHeader>
                {!!reviewsTotal && (
                  <Spacer top={1}>
                    <Flex gutter={1}>
                      <Icon
                        alt="star filled"
                        iconMaxHeight={16}
                        iconMaxWidth={16}
                        src={getPublicAsset("icons/reviews-star-filled.svg")}
                      />
                      <LinkWrapper onClick={handleReviewsClick}>
                        <Text
                          body="14-Regular"
                          dataQa="user-reviews-description"
                        >
                          {`${reviewsTotal} ${getReviewsSummaryHeaderText(reviewsTotal)}`}
                        </Text>
                      </LinkWrapper>
                    </Flex>
                  </Spacer>
                )}
                <UserDetailsSection.Membership>
                  <UserDetailsSection.Column>
                    <b>{translate("inbox-lister.member-since")}</b>
                    <div>{user.member_since}</div>
                  </UserDetailsSection.Column>
                  <UserDetailsSection.Column>
                    <b>{translate("inbox-lister.last-active")}</b>
                    <div>{user.last_active}</div>
                  </UserDetailsSection.Column>
                </UserDetailsSection.Membership>

                <UserDetailsSection.Membership>
                  <BioContainer>{user.small_bio}</BioContainer>
                </UserDetailsSection.Membership>
                {!!(
                  user.languages_by_affinity &&
                  user.languages_by_affinity.length
                ) && (
                  <UserDetailExtra>
                    <b>{translate("inbox-lister.languages")}</b>
                    <div>
                      {user.languages_by_affinity.map((ln, indx) => {
                        let label = translate(ln.title);
                        if (indx < user.languages_by_affinity.length - 1)
                          label += ",";
                        return (
                          <LanguageSpan key={ln.value}>{label}</LanguageSpan>
                        );
                      })}
                    </div>
                  </UserDetailExtra>
                )}
                {user.profession && (
                  <UserDetailExtra>
                    <b>{translate("inbox-lister.profession")}</b>
                    <span>{translate(user.profession.title)}</span>
                  </UserDetailExtra>
                )}
                {user.studies && (
                  <UserDetailExtra>
                    <b>{translate("inbox-lister.studies")}</b>
                    <span>{translate(user.studies.title)}</span>
                  </UserDetailExtra>
                )}
              </UserDetailsSection>
            </Column>
            <ColumnRight>
              {Object.keys(user.tags).length > 0 && (
                <PersonalityAndInterestsSection tags={user.tags} />
              )}
              {!!socialProfiles?.length &&
                socialProfiles.map((profile) => (
                  <SocialProfile
                    icon={profile.icon}
                    key={profile.media}
                    media={profile.media}
                    profileUserId={profileUserId}
                    value={profile.value}
                  />
                ))}
            </ColumnRight>
          </FlexHorizontal>
        </CardScroll>

        {currentCard && (
          <BadiGoldSection>
            <RoomInformation>
              <RoomInformation.Section>
                <RoomPicture src={room.picture} alt="picture of room" />
                <div>
                  <div>
                    {translate("inbox-lister.room-information.heading")}
                  </div>
                  <div>
                    <b>{room.title}</b>
                  </div>
                </div>
              </RoomInformation.Section>
              <RoomInformation.SectionRight>
                <div>
                  {translate("inbox-lister.room-information.requested")}
                </div>
                <div>
                  <b>{sentAt}</b>
                </div>
              </RoomInformation.SectionRight>
            </RoomInformation>

            <CtaSection>
              <CtaSection.TextSubsection>
                <Heading
                  level={4}
                  color={
                    hasBadiGold ? BADI_COLORS.ADDITIONAL.ADDITIONAL_06 : null
                  }
                >
                  {currentRequest.matchesStatusLabel}
                </Heading>
              </CtaSection.TextSubsection>
              <div>
                {payToMatchRequired ? (
                  <CtaSection.LinkButton
                    href={getGoldUpgradePath({
                      roomId: room.id,
                      initiator: getPathToRequests(currentRequest.id),
                    })}
                    variant={BUTTON_TYPES.GOLD_GRADIENT}
                  >
                    {translate("listing.overview.badi_gold.desktop_cta")}
                  </CtaSection.LinkButton>
                ) : (
                  <CtaSection.AcceptAndRejectContainer>
                    <Button
                      variant={BUTTON_TYPES.SECONDARY}
                      fullWidth={true}
                      onClick={onClickRejectConnection}
                    >
                      {translate("Reject")}
                    </Button>
                    <Button
                      fullWidth={true}
                      variant={hasBadiGold ? BUTTON_TYPES.GOLD_GRADIENT : null}
                      onClick={onClickAcceptConnection}
                    >
                      {translate("Accept")}
                    </Button>
                  </CtaSection.AcceptAndRejectContainer>
                )}
              </div>
            </CtaSection>
          </BadiGoldSection>
        )}
      </TopSectionContainer>
    </FlexVertical>
  );
};

export default RequestCard;
