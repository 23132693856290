import { DEFAULT_CATEGORY_VALUE } from "../constants";
import InboxRoomModel from "./inbox-rooms-model";

const InboxModel = {
  fetched: 0,
  fetching: false,
  error: null,
  connections: [],
  roomsSummary: {
    pagination: {},
  },
  room: new InboxRoomModel().toJSON(),
  pagination: {},
  category: DEFAULT_CATEGORY_VALUE,
  perPage: 30,
  notificationsCount: 0,
  notificationsListerCount: 0,
  notificationsSeekerCount: 0,
};

function setInitialState(initialState) {
  const state = initialState;

  if (!state || !state.Inbox) {
    return { ...InboxModel, error: "There was an error in InboxModel" };
  }
  return state;
}

export { InboxModel, setInitialState };
