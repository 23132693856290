import React from "react";
import { Heading } from "@badi/badi-components";
import Image from "components/Image/Image";
import { translate } from "base/shared/Localization";
import styled from "styled-components";

const Components = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10rem;
  `,
};

const EmptyState = () => (
  <Components.Container>
    <Image
      alt={translate("inbox.requests.empty.title")}
      src="/assets/images/inbox-empty.svg"
    />
    <Heading level={3}>{translate("inbox.requests.empty.title")}</Heading>
  </Components.Container>
);

export default EmptyState;
