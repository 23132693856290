import React from "react";
import styled from "styled-components";

const Components = {
  Container: styled.div`
    color: white;
    height: calc(100vh - 180px);
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle fill="%2304BF95" stroke="%2304BF95" stroke-width="24" r="15" cx="40" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="%2304BF95" stroke="%2304BF95" stroke-width="24" r="15" cx="100" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="%2304BF95" stroke="%2304BF95" stroke-width="24" r="15" cx="160" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg>');
  `,
};

const Loading = () => <Components.Container>LOADING</Components.Container>;

export default Loading;
