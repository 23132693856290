import createReducer from "base/shared/createReducer";

import Types from "./types";
import { InboxModel } from "./models/inbox-model";

import {
  parseConnections,
  parsePagination,
  updateConnections,
  parseRoomsSummary,
} from "./utils";

function inboxError(state, action) {
  return {
    ...state,
    fetched: Date.now(),
    fetching: false,
    error: action.error,
  };
}

function inboxRequest(state) {
  return { ...state, fetching: true, error: null };
}

function inboxSuccess(state, action) {
  const {
    data: {
      badge_count: badgeCount,
      badge_count_as_lister: listerCount,
      badge_count_as_seeker: seekerCount,
      connections,
    },
    pagination,
  } = action.result;

  return {
    ...state,
    fetched: Date.now(),
    fetching: false,
    error: null,
    connections: parseConnections(connections),
    pagination: parsePagination(pagination),
    notificationsCount: badgeCount,
    notificationsListerCount: listerCount,
    notificationsSeekerCount: seekerCount,
  };
}

function setInboxRoomsSummaryError(state, action) {
  return {
    ...state,
    fetched: Date.now(),
    error: action.error,
  };
}

function setInboxRoomsSummaryRequest(state) {
  return { ...state, fetching: true, error: null };
}

function setInboxRoomsSummarySuccess(state, action) {
  const {
    data: { rooms },
    pagination,
  } = action.result;

  return {
    ...state,
    fetched: Date.now(),
    fetching: false,
    error: null,
    roomsSummary: {
      rooms: parseRoomsSummary(rooms),
      pagination: parsePagination(pagination),
    },
  };
}

function inboxConnectionUpdate(state, action) {
  const { category, connections, perPage } = state;

  const newConnections = updateConnections(
    category,
    perPage,
    connections,
    action.connection,
  );

  return {
    ...state,
    connections: newConnections,
  };
}

function setInboxCategory(state, action) {
  return { ...state, category: action.payload };
}

function setInboxRoom(state, action) {
  return { ...state, room: action.payload };
}

function setConnectionsPerPage(state, action) {
  return { ...state, perPage: action.payload };
}

function setNotificationsCount(state, action) {
  return { ...state, notificationsCount: action.payload.count };
}

function setUnreadConnectionsSuccess(state, { result }) {
  const {
    data: {
      badge_count: badgeCount,
      badge_count_as_lister: listerCount,
      badge_count_as_seeker: seekerCount,
    },
  } = result;

  return {
    ...state,
    notificationsCount: badgeCount,
    notificationsListerCount: listerCount,
    notificationsSeekerCount: seekerCount,
  };
}

function setUnreadConnectionsRequest(state) {
  return state;
}

function setUnreadConnectionsError(state) {
  return state;
}

function clearConnections() {
  return InboxModel;
}

function cleanInboxRoom(state, action) {
  return { ...state, room: action.payload };
}

const actionHandlers = {
  [Types.INBOX_ERROR]: inboxError,
  [Types.INBOX_REQUEST]: inboxRequest,
  [Types.INBOX_SUCCESS]: inboxSuccess,
  [Types.INBOX_CLEAR_CONNECTIONS]: clearConnections,
  [Types.INBOX_CONNETION_UPDATE]: inboxConnectionUpdate,
  [Types.INBOX_CATEGORY_SET]: setInboxCategory,
  [Types.INBOX_ROOM_SET]: setInboxRoom,
  [Types.INBOX_CONNTECTIONS_PER_PAGE_SET]: setConnectionsPerPage,
  [Types.INBOX_NOTIFICATIONS_COUNT]: setNotificationsCount,
  [Types.INBOX_UNREAD_CONNECTIONS_REQUEST]: setUnreadConnectionsRequest,
  [Types.INBOX_UNREAD_CONNECTIONS_ERROR]: setUnreadConnectionsError,
  [Types.INBOX_UNREAD_CONNECTIONS_SUCCESS]: setUnreadConnectionsSuccess,
  [Types.INBOX_ROOMS_SUMMARY_REQUEST]: setInboxRoomsSummaryRequest,
  [Types.INBOX_ROOMS_SUMMARY_SUCCESS]: setInboxRoomsSummarySuccess,
  [Types.INBOX_ROOMS_SUMMARY_ERROR]: setInboxRoomsSummaryError,
  [Types.INBOX_ROOM_CLEAN]: cleanInboxRoom,
};

export default createReducer(actionHandlers, { ...InboxModel });
