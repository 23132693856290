import React, { useState } from "react";
import { connect } from "react-redux";
import { getToken } from "datamodel/Auth/selectors";
import InboxLister from "./inbox-lister";
import { getIsUserInListerMode } from "app/datamodel/User/selectors";
import { getInboxRoomId, getInboxRoomTitle } from "datamodel/Inbox/selectors";
import InboxActions from "datamodel/Inbox/actions";

const mapStateToProps = (state) => ({
  token: getToken(state),
  roomTitle: getInboxRoomTitle(state),
  room: getInboxRoomId(state),
  isUserInListerMode: getIsUserInListerMode(state),
});

const InboxListerMapping = (props) => {
  const [key, setKey] = useState(0);
  const reload = () => setKey(key + 1);
  return <InboxLister {...{ ...props, reload, key }} />;
};

const mapDispatchToProps = {
  cleanInboxRoom: InboxActions.cleanInboxRoom,
};

const mergeProps = (state, dispatchProps, ownProps) => ({
  ...state,
  ...dispatchProps,
  ...ownProps,
  category: ownProps.category || state.category,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(InboxListerMapping);
